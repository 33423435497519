import * as actionLabels from '../../actionLabels';

// GET PROFILE DATA

export const getProfileStart = () => ({
  type: actionLabels.GET_PROFILE_START,
});

export const getProfile = payload => ({
  type: actionLabels.GET_PROFILE_SAGA,
  payload,
});

export const getProfileSuccess = payload => ({
  type: actionLabels.GET_PROFILE_SUCCESS,
  payload,
});

export const getProfileFail = payload => ({
  type: actionLabels.GET_PROFILE_FAIL,
  payload,
});

// Update number

export const updateNumberStart = () => ({
  type: actionLabels.UPDATE_NUMBER_START,
});

export const updateNumber = payload => ({
  type: actionLabels.UPDATE_NUMBER_SAGA,
  payload,
});

export const updateNumberSuccess = payload => ({
  type: actionLabels.UPDATE_NUMBER_SUCCESS,
  payload,
});

export const updateNumberFail = payload => ({
  type: actionLabels.UPDATE_NUMBER_FAIL,
  payload,
});

// Update number

export const resendMobileOtpStart = () => ({
  type: actionLabels.RESEND_MOBILE_OTP_START,
});

export const resendMobileOtp = payload => ({
  type: actionLabels.RESEND_MOBILE_OTP_SAGA,
  payload,
});

export const resendMobileOtpSuccess = payload => ({
  type: actionLabels.RESEND_MOBILE_OTP_SUCCESS,
  payload,
});

export const resendMobileOtpFail = payload => ({
  type: actionLabels.RESEND_MOBILE_OTP_FAIL,
  payload,
});
