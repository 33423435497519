import axios from 'axios';

const axiosMain = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_END_POINT_URL_DEV
      : process.env.REACT_APP_END_POINT_URL_PROD,
  headers: {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': '69420',
  },
});

export default axiosMain;

export const setBaseUrl = baseUrl => {
  axiosMain.defaults.baseURL = baseUrl;
};
