/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */

import { useCallback } from "react";
import { useDispatch } from "react-redux";
import toastr from 'toastr';

import { ChainId, isSupportedChain } from "../constants/chains";
import  getChainInfo  from "../constants/chainInfo";
import { currentNetworkHex } from "../constants/dataConstant";
import { FALLBACK_URLS, RPC_URLS } from "../utils/networks";

function getRpcUrl(chainId) {
  switch (chainId) {
    case ChainId.MAINNET:
    case ChainId.HARDHAT:
      return RPC_URLS[chainId][0];
    // Attempting to add a chain using an infura URL will not work, as the URL will be unreachable from the MetaMask background page.
    // MetaMask allows switching to any publicly reachable URL, but for novel chains, it will display a warning if it is not on the "Safe" list.
    // See the definition of FALLBACK_URLS for more details.
    default:
      return FALLBACK_URLS[chainId][0];
  }
}

function useSwitchChain() {
  const dispatch = useDispatch();

  return useCallback(
    async (connector, chainId, provider) => {
      try {
        if (!isSupportedChain(chainId)) {
          throw new Error(
            `Chain ${chainId} not supported for connector (${typeof connector})`
          );
        } else {
          // dispatch(startSwitchingChain(chainId));
          try {
          
              const info = getChainInfo(chainId);
              const addChainParameter = {
                chainId,
                chainName: info?.label,
                rpcUrls: [getRpcUrl(chainId)],
                nativeCurrency: info?.nativeCurrency,
                blockExplorerUrls: [info?.explorer],
              };
              await connector.activate(addChainParameter);
            
          } catch (switchError) {
            if (switchError?.code === 4001) {
              toastr.error(switchError?.message);
            } else {
              // if (switchError.code === 4902) {
              try {
                const info = getChainInfo(chainId);
                try {
                  // await window?.ethereum?.request({
                  await provider?.provider?.request({
                    id: 1,
                    jsonrpc: "2.0",
                    method: "wallet_addEthereumChain",
                    params: [
                      {
                        chainId: currentNetworkHex,
                        rpcUrls: [getRpcUrl(chainId)],
                        chainName: info?.label,
                        nativeCurrency: info?.nativeCurrency,
                        blockExplorerUrls: [info?.explorer],
                        iconUrls: [
                          "https://celo.org/images/marketplace-icons/icon-celo-CELO-color-f.svg",
                        ],
                      },
                    ],
                  });
                } catch (error) {
                  console.log({ error });
                }
              } catch (error) {
                console.log(error);
              }
            }
            // }
            //   }
            //  catch (error) {
            //   console.log({ error });
            //   try {
            //     await connector.activate();
            //   } catch (error) {
            //     console.error("Failed to re-activate connector", error);
            //   }
            //   throw error;
          } finally {
            // dispatch(endSwitchingChain());
          }
        }
      } catch (err) {
        console.log({ err });
      }
    },
    [dispatch]
  );
}

export default useSwitchChain;