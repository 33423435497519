import * as actionLabels from '../../actionLabels';

export const initialState = {
  isLoading: false,
  errorMsg: '',
  rewardList: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // REWARD LIST
    case actionLabels.GET_REWARD_LIST_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_REWARD_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        rewardList: payload?.items[0],
        errorMsg: '',
      };
    }
    case actionLabels.GET_REWARD_LIST_FAIL: {
      return { ...state, isLoading: false, errorMsgAp: payload };
    }

    default:
      return state;
  }
};
