/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */

import { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import { authenticationValidator } from './store/actions';
import { Spinner } from './components';
import { guestRoutes } from './routes';
import useActivationState from './views/Layout/VerticalLayout/activate';
import { setPersistedConnectionMeta } from './utils/meta';
import { injectedConnection } from './utils/connectors';
import useSwitchChain from './hooks/useSwitchChain';

// Import scss
import './assets/scss/theme.scss';
import './app.css';
import { currentNetwork } from './constants/dataConstant';
import { didUserReject } from './utils';

function App() {
  const tokenPresent = !!useSelector(state => state.auth.authToken);

  const pathname = window.location.pathname.split('/')[1];
  const { account, provider, chainId, connector } = useWeb3React();
  const { tryActivation } = useActivationState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authenticationValidator());
  }, []);

  const redirectHandler = () => {
    const guestRoute = guestRoutes
      .filter(item => item.redirectRoute === undefined)
      .map(item => item.path);
    return !guestRoute.includes(`/${pathname}`) && localStorage.getItem('authToken') === null ? (
      <Redirect to="/signin" />
    ) : null;
  };

  let mainContent = (
    <>
      {guestRoutes.map(
        route =>
          route.redirectRoute === undefined && (
            <Route key={route.name} path={route.path} exact={route.exact} name={route.name}>
              <route.component />
            </Route>
          ),
      )}
      {redirectHandler()}
    </>
  );
  if (tokenPresent) {
    mainContent = (
      <>
        <Route path="/" component={lazy(() => import('./views/MainContainer/MainContainer'))} />
      </>
    );
  }

  const activate = () => {
    connector.resetState();
    tryActivation(
      injectedConnection,
      () => {
        setPersistedConnectionMeta('INJECTED');
      },
      chainId,
    );
  };

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (localStorage?.getItem('connection_meta') === 'INJECTED') {
        try {
          activate();
        } catch (ex) {
          console.log(ex);
        }
      }
    };
    connectWalletOnPageLoad();
  }, []);

  const switchChain = useSwitchChain();
  const switchNetwork = async () => {
    if (currentNetwork !== chainId) {
      try {
        await switchChain(connector, currentNetwork, provider);
      } catch (error) {
        if (didUserReject(error)) {
          // Ignore error, which keeps the user on the previous chain.
        } else {
          throw error;
        }
      }
    }
  };

  // wrong chain id detected
  useEffect(() => {
    if (account && chainId) switchNetwork();
  }, [account, chainId, connector]);

  return (
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <Switch>{mainContent}</Switch>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
