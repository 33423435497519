export const ChainId = {
  MAINNET: 1,
  GOERLI: 5,
  SEPOLIA: 11155111,
  OPTIMISM: 10,
  OPTIMISM_GOERLI: 420,
  ARBITRUM_ONE: 42161,
  ARBITRUM_GOERLI: 421613,
  POLYGON: 137,
  POLYGON_AMOY: 80002,
  CELO: 42220,
  CELO_ALFAJORES: 44787,
  GNOSIS: 100,
  MOONBEAM: 1284,
  BNB: 56,
  AVALANCHE: 43114,
  BASE_GOERLI: 84531,
  BASE: 8453,
  HARDHAT: 31337,
};

export const MAINNET = 1;
export const GOERLI = 5;
export const SEPOLIA = 11155111;
export const OPTIMISM = 10;
export const OPTIMISM_GOERLI = 420;
export const ARBITRUM_ONE = 42161;
export const ARBITRUM_GOERLI = 421613;
export const POLYGON = 137;
export const POLYGON_AMOY = 80002;
export const CELO = 42220;
export const CELO_ALFAJORES = 44787;
export const GNOSIS = 100;
export const MOONBEAM = 1284;
export const BNB = 56;
export const AVALANCHE = 43114;
export const BASE_GOERLI = 84531;
export const BASE = 8453;
export const HARDHAT = 31337;

export const SUPPORTED_CHAINS = [
  ChainId.MAINNET,
  ChainId.OPTIMISM,
  ChainId.OPTIMISM_GOERLI,
  ChainId.ARBITRUM_ONE,
  ChainId.ARBITRUM_GOERLI,
  ChainId.POLYGON,
  ChainId.POLYGON_AMOY,
  ChainId.GOERLI,
  ChainId.SEPOLIA,
  ChainId.CELO_ALFAJORES,
  ChainId.CELO,
  ChainId.BNB,
  ChainId.AVALANCHE,
  ChainId.BASE,
  ChainId.BASE_GOERLI,
  ChainId.HARDHAT,
];

const NOT_YET_UX_SUPPORTED_CHAIN_IDS = [ChainId.BASE_GOERLI];

export function isSupportedChain(chainId, featureFlags) {
  if (featureFlags && chainId && chainId in featureFlags) {
    return featureFlags[chainId];
  }
  return (
    !!chainId &&
    SUPPORTED_CHAINS.indexOf(chainId) !== -1 &&
    NOT_YET_UX_SUPPORTED_CHAIN_IDS.indexOf(chainId) === -1
  );
}
