import * as actionLabels from '../../actionLabels';

export const initialState = {
  // referral list
  isLoading: false,
  errorMsg: '',
  referralList: [],
  // get referral %
  getReferralPercentLoading: false,
  getReferralPercentErrorMSg: '',
  referralPercent: null,
  // update referral %
  updateReferralPercentLoading: false,
  updateReferralPercentErrorMSg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // referral list
    case actionLabels.GET_REFERRAL_LIST_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_REFERRAL_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        referralList: payload?.items[0],
        errorMsg: '',
      };
    }
    case actionLabels.GET_REFERRAL_LIST_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    // get referral %
    case actionLabels.GET_REFERRAL_PERCENT_START:
      return { ...state, getReferralPercentLoading: true };
    case actionLabels.GET_REFERRAL_PERCENT_SUCCESS: {
      return {
        ...state,
        getReferralPercentLoading: false,
        referralPercent: payload?.items?.rewardPercentage,
        getReferralPercentErrorMSg: '',
      };
    }
    case actionLabels.GET_REFERRAL_PERCENT_FAIL: {
      return { ...state, getReferralPercentLoading: false, getReferralPercentErrorMSg: payload };
    }

    // update referral %
    case actionLabels.UPDATE_REFERRAL_PERCENT_START:
      return { ...state, updateReferralPercentLoading: true };
    case actionLabels.UPDATE_REFERRAL_PERCENT_SUCCESS: {
      return {
        ...state,
        updateReferralPercentLoading: false,
        updateReferralPercentErrorMSg: '',
      };
    }
    case actionLabels.UPDATE_REFERRAL_PERCENT_FAIL: {
      return {
        ...state,
        updateReferralPercentLoading: false,
        updateReferralPercentErrorMSg: payload,
      };
    }

    default:
      return state;
  }
};
