import * as actionLabels from '../../actionLabels';

export const initialState = {
  isLoading: false,
  errorMsg: '',
  dashboardData: null,
  dashboardDetails: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.SET_DEFAULT:
      return { ...state, isLogin: false };

    case actionLabels.DASHBOARD_STATS_START:
      return { ...state, isLoading: true };

    case actionLabels.DASHBOARD_STATS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dashboardData: payload.data.items,
        errorMsg: '',
      };
    }
    case actionLabels.DASHBOARD_STATS_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    case actionLabels.GET_DASHBOARD_DETAILS_START:
      return { ...state, isLoading: true };

    case actionLabels.GET_DASHBOARD_DETAILS_SUCCESS: {
      const data = [];
      data.push(payload.data.items);
      return {
        ...state,
        isLoading: false,
        dashboardDetails: data,
        errorMsg: '',
      };
    }
    case actionLabels.GET_DASHBOARD_DETAILS_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    default:
      return state;
  }
};
