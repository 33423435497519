import * as actionLabels from '../../actionLabels';

export const initialState = {
  isLoading: false,
  errorMsg: '',
  phaseData: null,
  // GET_ICO_PHASE_DETAILS
  getIcoPhaseDetailsLoading: false,
  getIcoPhaseDetailsErrorMsg: '',
  icoPhaseDetails: null,
  // UPDATE_START_DATE
  updateStartdateLoading: false,
  updateStartdateErrorMsg: '',
  // SET_ROUND
  setRoundLoading: false,
  setRoundErrorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.SET_DEFAULT:
      return { ...state, isLogin: false };

    case actionLabels.UPLOAD_PHASE_TIMINGS_START:
      return { ...state, isLoading: true };
    case actionLabels.UPLOAD_PHASE_TIMINGS_SUCCESS: {
      return {
        ...state,
        phaseData: payload,
        errorMsg: '',
      };
    }
    case actionLabels.UPLOAD_PHASE_TIMINGS_FAIL: {
      return { ...state, isLoading: false, errorMsgAp: payload };
    }

    // GET_ICO_PHASE_DETAILS
    case actionLabels.GET_ICO_PHASE_DETAILS_START:
      return { ...state, getIcoPhaseDetailsLoading: true };
    case actionLabels.GET_ICO_PHASE_DETAILS_SUCCESS: {
      return {
        ...state,
        getIcoPhaseDetailsLoading: false,
        getIcoPhaseDetailsErrorMsg: '',
        icoPhaseDetails: payload?.data?.items,
      };
    }
    case actionLabels.GET_ICO_PHASE_DETAILS_FAIL: {
      return { ...state, getIcoPhaseDetailsLoading: false, getIcoPhaseDetailsErrorMsg: payload };
    }

    // UPDATE_START_DATE
    case actionLabels.UPDATE_START_DATE_START:
      return { ...state, updateStartdateLoading: true };
    case actionLabels.UPDATE_START_DATE_SUCCESS: {
      return {
        ...state,
        updateStartdateLoading: false,
        updateStartdateErrorMsg: '',
      };
    }
    case actionLabels.UPDATE_START_DATE_FAIL: {
      return { ...state, updateStartdateLoading: false, updateStartdateErrorMsg: payload };
    }

    // SET_ROUND
    case actionLabels.SET_ROUND_START:
      return { ...state, setRoundLoading: true };
    case actionLabels.SET_ROUND_SUCCESS: {
      return {
        ...state,
        setRoundLoading: false,
        setRoundErrorMsg: '',
      };
    }
    case actionLabels.SET_ROUND_FAIL: {
      return { ...state, setRoundLoading: false, setRoundErrorMsg: payload };
    }

    default:
      return state;
  }
};
