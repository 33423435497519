// get all rewards
export const GET_REWARD_LIST_SAGA = 'GET_REWARD_LIST_SAGA';
export const GET_REWARD_LIST_START = 'GET_REWARD_LIST_START';
export const GET_REWARD_LIST_SUCCESS = 'GET_REWARD_LIST_SUCCESS';
export const GET_REWARD_LIST_FAIL = 'GET_REWARD_LIST_FAIL';

// approve reward
export const APPROVE_REWARD_SAGA = 'APPROVE_REWARD_SAGA';
export const APPROVE_REWARD_START = 'APPROVE_REWARD_START';
export const APPROVE_REWARD_SUCCESS = 'APPROVE_REWARD_SUCCESS';
export const APPROVE_REWARD_FAIL = 'APPROVE_REWARD_FAIL';

// change reward
export const CHANGE_REWARD_SAGA = 'CHANGE_REWARD_SAGA';
export const CHANGE_REWARD_START = 'CHANGE_REWARD_START';
export const CHANGE_REWARD_SUCCESS = 'CHANGE_REWARD_SUCCESS';
export const CHANGE_REWARD_FAIL = 'CHANGE_REWARD_FAIL';
