/* eslint-disable import/no-extraneous-dependencies */

import { useCallback } from "react";
import { atom } from "jotai";
import { useAtomValue, useUpdateAtom } from "jotai/utils";
import { useDispatch } from "react-redux";

import { didUserReject } from "../../../utils";

const IDLE_ACTIVATION_STATE = { status: "IDLE" };

const activationStateAtom = atom(IDLE_ACTIVATION_STATE);

function useTryActivation() {
  const dispatch = useDispatch();
  const setActivationState = useUpdateAtom(activationStateAtom);

  return useCallback(
    async (connection, onSuccess, chainId) => {
      // Skips wallet connection if the connection should override the default
      // behavior, i.e. install MetaMask or launch Coinbase app
      if (connection.overrideActivate?.(chainId)) return;

      try {
        setActivationState({ status: "PENDING", connection });

        console.debug(`Connection activating: ${connection.getName()}`);
     
        await connection.connector.activate();

        console.debug(`Connection activated: ${connection.getName()}`);
    

        // Clears pending connection state
        setActivationState(IDLE_ACTIVATION_STATE);

        onSuccess();
      } catch (error) {
        // Gracefully handles errors from the user rejecting a connection attempt
        if (didUserReject(connection, error)) {
          setActivationState(IDLE_ACTIVATION_STATE);
          return;
        }

        // TODO(WEB-1859): re-add special treatment for already-pending injected errors & move debug to after didUserReject() check
        console.debug(`Connection failed: ${connection.getName()}`);
        console.error(error);

        // Failed Connection events are logged here, while successful ones are logged by Web3Provider
        // sendAnalyticsEvent(InterfaceEventName.WALLET_CONNECTED, {
        //   result: WalletConnectionResult.FAILED,
        //   wallet_type: connection.getName(),
        //   page: currentPage,
        // });
        setActivationState({ status: "ERROR", connection, error });
      }
    },
    [dispatch, setActivationState]
  );
}

function useCancelActivation() {
  const setActivationState = useUpdateAtom(activationStateAtom);
  return useCallback(
    () =>
      setActivationState((activationState) => {
        if (activationState.status !== "IDLE")
          activationState.connection.connector.deactivate?.();
        return IDLE_ACTIVATION_STATE;
      }),
    [setActivationState]
  );
}

export default function useActivationState() {
  const activationState = useAtomValue(activationStateAtom);
  const tryActivation = useTryActivation();
  const cancelActivation = useCancelActivation();

  return { activationState, tryActivation, cancelActivation };
}
