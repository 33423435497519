import * as actionLabels from '../../actionLabels';

export const initialState = {
  isLogin: false,
  isLoading: false,
  userData: null,
  otpData: null,
  authToken: '',
  errorMsg: '',
  errorMsgAp: '',
  fcmToken: '',
  email: '',
  resetPassword: '',
  activatePassword: null,
  profileData: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.SET_DEFAULT:
      return { ...state, isLogin: false };

    case actionLabels.LOGIN_START:
      return { ...state, isLoading: true };

    case actionLabels.LOGIN_SUCCESS: {
      return {
        ...state,
        isLogin: true,
        isLoading: false,
        authToken: payload?.data?.token || payload?.token,
        // fcmToken: payload.deviceToken,
        userData: payload,
        errorMsg: '',
      };
    }
    case actionLabels.LOGIN_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    case actionLabels.RESEND_OTP_START:
      return { ...state, isLoading: false };
    case actionLabels.RESEND_OTP_SUCCESS: {
      return {
        ...state,
        // isLoading: false,
        // isLogin: true,
        // isLoading: false,
        // authToken: payload.token,
        // fcmToken: payload.deviceToken,
        otpData: payload,
        errorMsg: '',
      };
    }
    case actionLabels.RESEND_OTP_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    case actionLabels.ACTIVATE_PASSWORD_START:
      return { ...state, isLoading: false };
    case actionLabels.ACTIVATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        // isLoading: false,
        // isLogin: true,
        // isLoading: false,
        // authToken: payload.token,
        // fcmToken: payload.deviceToken,
        activatePassword: payload,
        errorMsg: '',
      };
    }
    case actionLabels.ACTIVATE_PASSWORD_FAIL: {
      return { ...state, isLoading: false, errorMsgAp: payload };
    }
    case actionLabels.RESET_ACTIVATE_PASSWORD :{
      return { ...state, isLoading: false, errorMsgAp: '' }; 
    }
    case actionLabels.RESET_PASSWORD_START:
      return { ...state, isLoading: false };
    case actionLabels.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        // isLoading: false,
        // isLogin: true,
        // isLoading: false,
        // authToken: payload.token,
        // fcmToken: payload.deviceToken,
        // activatePassword: payload,
        resetPassword: payload,
        errorMsg: '',
      };
    }
    case actionLabels.RESET_PASSWORD_FAIL: {
      return { ...state, isLoading: false, errorMsgAp: payload };
    }

    default:
      return state;
  }
};
