export const RESET_APP = 'RESET_APP';

export const LOGIN_SAGA = 'LOGIN_SAGA';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const ACTIVATE_PASSWORD_SAGA = 'ACTIVATE_PASSWORD_SAGA';
export const ACTIVATE_PASSWORD_START = 'ACTIVATE_PASSWORD_START';
export const ACTIVATE_PASSWORD_SUCCESS = 'ACTIVATE_PASSWORD_SUCCESS';
export const ACTIVATE_PASSWORD_FAIL = 'ACTIVATE_PASSWORD_FAIL';
export const RESET_ACTIVATE_PASSWORD = 'RESET_ACTIVATE_PASSWORD';


export const RESEND_OTP_SAGA = 'RESEND_OTP_SAGA';
export const RESEND_OTP_START = 'RESEND_OTP_START';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_FAIL = 'RESEND_OTP_FAIL';

export const RESET_PASSWORD_SAGA = 'RESET_PASSWORD_SAGA';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const SET_DEFAULT = 'SET_DEFAULT';

export const AUTHENTICATION_VALIDATOR = 'AUTHENTICATION_VALIDATOR';
