import * as actionLabels from '../../actionLabels';

// REWARD LIST
export const getRewardListAction = payload => ({
  type: actionLabels.GET_REWARD_LIST_SAGA,
  payload,
});
export const getRewardListStart = payload => ({
  type: actionLabels.GET_REWARD_LIST_START,
  payload,
});

export const getRewardListSuccess = payload => ({
  type: actionLabels.GET_REWARD_LIST_SUCCESS,
  payload,
});

export const getRewardListFail = payload => ({
  type: actionLabels.GET_REWARD_LIST_FAIL,
  payload,
});

// approve reward
export const approveRewardList = payload => ({
  type: actionLabels.APPROVE_REWARD_SAGA,
  payload,
});
export const approveRewardListStart = payload => ({
  type: actionLabels.APPROVE_REWARD_START,
  payload,
});

export const approveRewardListSuccess = payload => ({
  type: actionLabels.APPROVE_REWARD_SUCCESS,
  payload,
});

export const approveRewardListFail = payload => ({
  type: actionLabels.APPROVE_REWARD_FAIL,
  payload,
});
