import * as actionLabels from '../../actionLabels';

// FOR GET ALL platform variables
export const getPlatformVariablesStart = payload => ({
  type: actionLabels.GET_PLATFORM_VARIABLES_START,
  payload,
});

export const getPlatformVariables = payload => ({
  type: actionLabels.GET_PLATFORM_VARIABLES_SAGA,
  payload,
});

export const getPlatformVariablesSuccess = payload => ({
  type: actionLabels.GET_PLATFORM_VARIABLES_SUCCESS,
  payload,
});

export const getPlatformVariablesFail = payload => ({
  type: actionLabels.GET_PLATFORM_VARIABLES_FAIL,
  payload,
});

// FOR edit platform variables
export const editPlatformVariablesStart = payload => ({
  type: actionLabels.EDIT_PLATFORM_VARIABLES_START,
  payload,
});

export const editPlatformVariables = payload => ({
  type: actionLabels.EDIT_PLATFORM_VARIABLES_SAGA,
  payload,
});

export const editPlatformVariablesSuccess = payload => ({
  type: actionLabels.EDIT_PLATFORM_VARIABLES_SUCCESS,
  payload,
});

export const editPlatformVariablesFail = payload => ({
  type: actionLabels.EDIT_PLATFORM_VARIABLES_FAIL,
  payload,
});
