import { combineReducers } from 'redux';
import auth from './auth/auth';
import modal from './modal/modal';
import admin from './admin/admin';
import user from './user/user';
import profile from './profile/profile';
import dashboard from './dashboard/dashboard';
import platform from './platform/platform';
import ico from './ico/ico';
import phaseManagement from './PhaseManagement/PhaseManagement';
import referral from './referral/referral';
import reward from './reward/reward';

const allReducers = combineReducers({
  auth,
  modal,
  admin,
  user,
  profile,
  dashboard,
  platform,
  ico,
  phaseManagement,
  referral,
  reward,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return allReducers(state, action);
};

export default rootReducer;
