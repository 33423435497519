export const UPLOAD_PHASE_TIMINGS_SAGA = 'UPLOAD_PHASE_TIMINGS_SAGA';
export const UPLOAD_PHASE_TIMINGS_START = 'UPLOAD_PHASE_TIMINGS_START';
export const UPLOAD_PHASE_TIMINGS_SUCCESS = 'UPLOAD_PHASE_TIMINGS_SUCCESS';
export const UPLOAD_PHASE_TIMINGS_FAIL = 'UPLOAD_PHASE_TIMINGS_FAIL';

export const GET_ICO_PHASE_DETAILS_SAGA = 'GET_ICO_PHASE_DETAILS_SAGA';
export const GET_ICO_PHASE_DETAILS_START = 'GET_ICO_PHASE_DETAILS_START';
export const GET_ICO_PHASE_DETAILS_SUCCESS = 'GET_ICO_PHASE_DETAILS_SUCCESS';
export const GET_ICO_PHASE_DETAILS_FAIL = 'GET_ICO_PHASE_DETAILS_FAIL';

export const UPDATE_START_DATE_SAGA = 'UPDATE_START_DATE_SAGA';
export const UPDATE_START_DATE_START = 'UPDATE_START_DATE_START';
export const UPDATE_START_DATE_SUCCESS = 'UPDATE_START_DATE_SUCCESS';
export const UPDATE_START_DATE_FAIL = 'UPDATE_START_DATE_FAIL';

export const SET_ROUND_SAGA = 'SET_ROUND_SAGA';
export const SET_ROUND_START = 'SET_ROUND_START';
export const SET_ROUND_SUCCESS = 'SET_ROUND_SUCCESS';
export const SET_ROUND_FAIL = 'SET_ROUND_FAIL';
