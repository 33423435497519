/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { put } from 'redux-saga/effects';
import toastr from 'toastr';

import {
  getReferralListFail,
  getReferralListStart,
  getReferralListSuccess,
  getReferralPercentFail,
  getReferralPercentStart,
  getReferralPercentSuccess,
  updateReferralPercentFail,
  updateReferralPercentStart,
  updateReferralPercentSuccess,
} from '../../actions';
import errorHandler from '../../../utils/apiHandler';
import {
  GET_REFERRAL_LIST_URL,
  GET_REFERRAL_PERCENT_URL,
  UPDATE_REFERRAL_PERCENT_URL,
} from '../../../apis';

// get referral list saga
export function* getReferralListSaga() {
  yield put(getReferralListStart());
  yield errorHandler({
    endpoint: GET_REFERRAL_LIST_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getReferralListSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getReferralListFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_DEV,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get referral percentage saga
export function* getReferralPercentSaga() {
  yield put(getReferralPercentStart());
  yield errorHandler({
    endpoint: GET_REFERRAL_PERCENT_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getReferralPercentSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getReferralPercentFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_DEV,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// update referral percentage saga
export function* updateReferralPercentSaga(action) {
  const { referralData, handleGetReferralPercent } = action?.payload;
  yield put(updateReferralPercentStart());
  yield errorHandler({
    endpoint: UPDATE_REFERRAL_PERCENT_URL,
    successHandler: yield function* (response) {
      yield put(updateReferralPercentSuccess(response?.data));
      if (handleGetReferralPercent) handleGetReferralPercent();
      toastr.success(response?.data?.message);
    },
    failHandler: yield function* (response) {
      yield put(updateReferralPercentFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_DEV,
    failHandlerType: 'CUSTOM',
    apiType: 'patch',
    payload: referralData,
    token: true,
  });
}
