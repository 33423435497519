import * as actionLabels from '../../actionLabels';

export const initialState = {
  isLoading: false,
  otpData: null,
  authToken: '',
  errorMsg: '',
  updateNumber: false,
  profileData: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.SET_DEFAULT:
      return { ...state, isLogin: false };

    case actionLabels.GET_PROFILE_START:
      return { ...state, isLoading: true };
    case actionLabels.GET_PROFILE_SUCCESS: {
      return {
        ...state,

        profileData: payload.data.items,
        errorMsg: '',
        isLoading: false,
      };
    }
    case actionLabels.GET_PROFILE_FAIL: {
      return { ...state, isLoading: false, errorMsgAp: payload };
    }

    case actionLabels.UPDATE_NUMBER_START:
      return { ...state, isLoading: false, updateNumber: false };
    case actionLabels.UPDATE_NUMBER_SUCCESS: {
      return {
        ...state,
        updateNumber: true,
        // profileData: payload.data.items,
        errorMsg: '',
      };
    }
    case actionLabels.UPDATE_NUMBER_FAIL: {
      return { ...state, isLoading: false, errorMsgAp: payload, updateNumber: false };
    }

    case actionLabels.RESEND_MOBILE_OTP_START:
      return { ...state, isLoading: false };
    case actionLabels.RESEND_MOBILE_OTP_SUCCESS: {
      return {
        ...state,

        // profileData: payload.data.items,
        errorMsg: '',
      };
    }
    case actionLabels.RESEND_MOBILE_OTP_FAIL: {
      return { ...state, isLoading: false, errorMsgAp: payload };
    }

    default:
      return state;
  }
};
