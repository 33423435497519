import * as actionLabels from '../../actionLabels';

// FOR update batch transfer data
export const updateBatchTransferStart = payload => ({
  type: actionLabels.UPDATE_BATCH_TRANSFER_START,
  payload,
});

export const updateBatchTransfer = payload => ({
  type: actionLabels.UPDATE_BATCH_TRANSFER_SAGA,
  payload,
});

export const updateBatchTransferSuccess = payload => ({
  type: actionLabels.UPDATE_BATCH_TRANSFER_SUCCESS,
  payload,
});

export const updateBatchTransferFail = payload => ({
  type: actionLabels.UPDATE_BATCH_TRANSFER_FAIL,
  payload,
});

// FOR GET all ICO data
export const getIcoStart = payload => ({
  type: actionLabels.GET_ICO_START,
  payload,
});

export const getIco = payload => ({
  type: actionLabels.GET_ICO_SAGA,
  payload,
});

export const getIcoSuccess = payload => ({
  type: actionLabels.GET_ICO_SUCCESS,
  payload,
});

export const getIcoFail = payload => ({
  type: actionLabels.GET_ICO_FAIL,
  payload,
});

// FOR Edit ICO data
export const editIcoStart = payload => ({
  type: actionLabels.EDIT_ICO_START,
  payload,
});

export const editIco = payload => ({
  type: actionLabels.EDIT_ICO_SAGA,
  payload,
});

export const editIcoSuccess = payload => ({
  type: actionLabels.EDIT_ICO_SUCCESS,
  payload,
});

export const editIcoFail = payload => ({
  type: actionLabels.EDIT_ICO_FAIL,
  payload,
});
