/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
/* eslint-disable import/prefer-default-export */
import { put } from 'redux-saga/effects';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import axiosMain from '../../../http/axios/axios_main';
import {
  activityLogsFail,
  activityLogsStart,
  activityLogsSuccess,
  addAdminFail,
  addAdminStart,
  addAdminSuccess,
  commonFail,
  commonStart,
  commonSuccess,
  deleteAdminFail,
  deleteAdminStart,
  deleteAdminSuccess,
  editAdminFail,
  editAdminStart,
  editAdminSuccess,
  getAllAdmin,
  getAllAdminFail,
  getAllAdminStart,
  getAllAdminSuccess,
  transactionDetailsFail,
  transactionDetailsStart,
  transactionDetailsSuccess,
  viewAdmin,
  viewAdminFail,
  viewAdminStart,
  viewAdminSuccess,
} from '../../actions/admin/admin';
import {
  getWhitelistedUsersFail,
  getWhitelistedUsersStart,
  getWhitelistedUsersSuccess,
} from '../../actions';
import errorHandler from '../../../utils/apiHandler';

export function* getAllAdminSaga(action) {
  const { params } = action.payload;

  let query = '';
  let arr = [];
  Object.keys(params).map(e => {
    if (params[e] !== '') {
      if (e === 'isDisabled') {
        arr.push(`${e}=${params[e] === 'inactive'}`);
      } else {
        arr.push(`${e}=${params[e]}`);
      }
    }
  });
  query = arr.join('&');
  yield put(getAllAdminStart());
  try {
    const response = yield axiosMain.get(
      // '/admin',
      `/admin?${query}`,
    );
    if (response.status === 200) {
      yield put(getAllAdminSuccess(response.data));
    } else {
      yield put(getAllAdminFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllAdminFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== '' &&
        typeof error.response.data.msg === 'string'
      ) {
        yield put(getAllAdminFail(error.response.data.msg));
      } else {
        yield put(getAllAdminFail('Something went wrong! Please try again.'));
      }
    } else {
      yield put(getAllAdminFail('Something went wrong! Please try again.'));
    }
  }
}

export function* addAdminSaga(action) {
  const { data } = action.payload;
  const { params } = action.payload;

  yield put(addAdminStart(data));
  try {
    const response = yield axiosMain.post('/admin', data);
    if (response.status === 201) {
      yield put(addAdminSuccess(response.data));
      yield toastr.success('New admin created');
      yield put(getAllAdmin({ params }));
    } else {
      yield put(addAdminFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    console.log(error.response.data.error.message, 'add-error');
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addAdminFail(error.response.data.msg));
        yield toastr.error(error.response.data.error.message);
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== '' &&
        typeof error.response.data.msg === 'string'
      ) {
        yield put(addAdminFail(error.response.data.msg));
        yield toastr.error(error.response.data.error.message);
      } else {
        yield put(addAdminFail('Something went wrong! Please try again.'));
        yield toastr.error(error.response.data.error.message);
      }
    } else {
      yield put(addAdminFail('Something went wrong! Please try again.'));
      yield toastr.error(error.response.data.error.message);
    }
  }
}

export function* deleteAdminSaga(action) {
  const { _id } = action.payload;
  const { params } = action.payload;

  yield put(deleteAdminStart());
  try {
    const response = yield axiosMain.delete(`/admin/${_id}`);
    if (response.status === 200) {
      yield put(deleteAdminSuccess(response.data));
      yield toastr.success('Admin deleted successfully');

      yield put(getAllAdmin({ params }));
    } else {
      yield put(deleteAdminFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(deleteAdminFail(error.response.data.msg));
        yield toastr.error('Something went wrong, admin was not deleted');
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== '' &&
        typeof error.response.data.msg === 'string'
      ) {
        yield put(deleteAdminFail(error.response.data.msg));
        yield toastr.error('Something went wrong, admin was not deleted');
      } else {
        yield put(deleteAdminFail('Something went wrong! Please try again.'));
        yield toastr.error('Something went wrong, admin was not deleted');
      }
    } else {
      yield put(deleteAdminFail('Something went wrong! Please try again.'));
      yield toastr.error('Something went wrong, admin was not deleted');
    }
  }
}

export function* editAdminSaga(action) {
  const { isDisable } = action.payload;
  const { data } = action.payload;
  const { _id } = action.payload;
  const { params } = action.payload;

  yield put(editAdminStart());
  try {
    const response = yield axiosMain.patch(`/admin/${_id}`, data || { isDisabled: isDisable });
    if (response.status === 200) {
      yield put(editAdminSuccess(response.data));
      yield put(getAllAdmin({ params }));
      yield put(viewAdmin({ _id }));
      yield toastr.success('Admin updated successfully');
    } else {
      yield put(editAdminFail('Something went wrong! Please try again.'));
      yield toastr.error('Something went wrong, admin was not edited');
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editAdminFail(error.response.data.error.message));
        yield toastr.error(error.response.data.error.message);
      } else if (
        error.response.data.error.message !== undefined &&
        error.response.data.error.message !== '' &&
        typeof error.response.data.error.message === 'string'
      ) {
        yield put(editAdminFail(error.response.data.error.message));
        yield toastr.error(error.response.data.error.message);
      } else {
        yield put(editAdminFail('Something went wrong! Please try again.'));
        yield toastr.error(error.response.data.error.message);
      }
    } else {
      yield put(editAdminFail('Something went wrong! Please try again.'));
      yield toastr.error(error.response.data.error.message);
    }
  }
}

export function* activityLogsSaga(action) {
  const { _id } = action.payload;

  yield put(activityLogsStart());
  try {
    const response = yield axiosMain.get(`admin/${_id}/activity-log`);
    if (response.status === 200) {
      yield put(activityLogsSuccess(response.data));
    } else {
      yield put(activityLogsFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(activityLogsFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== '' &&
        typeof error.response.data.msg === 'string'
      ) {
        yield put(activityLogsFail(error.response.data.msg));
      } else {
        yield put(activityLogsFail('Something went wrong! Please try again.'));
      }
    } else {
      yield put(activityLogsFail('Something went wrong! Please try again.'));
    }
  }
}

export function* viewAdminSaga(action) {
  const { _id } = action.payload;

  yield put(viewAdminStart());
  try {
    const response = yield axiosMain.get(`/admin/${_id}`);
    if (response.status === 200) {
      yield put(viewAdminSuccess(response.data));
    } else {
      yield put(viewAdminFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(viewAdminFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== '' &&
        typeof error.response.data.msg === 'string'
      ) {
        yield put(viewAdminFail(error.response.data.msg));
      } else {
        yield put(viewAdminFail('Something went wrong! Please try again.'));
      }
    } else {
      yield put(viewAdminFail('Something went wrong! Please try again.'));
    }
  }
}

export function* transactionDetailsSaga() {
  // const { queryParams } = action.payload;
  // const pk = localStorage.getItem('pk');
  // const sk = localStorage.getItem('sk');
  // console.log(action.payload, 'qryparms');
  try {
    yield put(transactionDetailsStart());

    const response = yield axiosMain.get(`transactions/all`);
    if (response.status === 200) {
      yield put(transactionDetailsSuccess(response.data));
    } else {
      yield put(transactionDetailsFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(transactionDetailsFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== '' &&
        typeof error.response.data.msg === 'string'
      ) {
        yield put(transactionDetailsFail(error.response.data.msg));
      } else {
        yield put(transactionDetailsFail('Something went wrong! Please try again.'));
      }
    } else {
      yield put(transactionDetailsFail('Something went wrong! Please try again.'));
    }
  }
}

export function* getWhitelistedSaga() {
  yield put(getWhitelistedUsersStart());
  yield errorHandler({
    endpoint: '/ico/whitelist-seed-address',
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getWhitelistedUsersSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getWhitelistedUsersFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_DEV,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

export function* whitelistUser() {
  yield put(getWhitelistedUsersStart());
  yield errorHandler({
    endpoint: '/ico/whitelist-seed-address',
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getWhitelistedUsersSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getWhitelistedUsersFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_DEV,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

export function* commonAPISaga(action) {
  const { endPoint, method, success, stateObj, payloadData } = action.payload;
  console.log(endPoint, method, success, stateObj, payloadData);
  yield put(commonStart({ stateObj }));
  yield errorHandler({
    endpoint: endPoint,
    successHandler: yield function* (response) {
      const { data } = response;
      // eslint-disable-next-line object-shorthand
      const resObj = { res: data, stateObj: stateObj };

      if (success) {
        success(data);
      }

      yield put(commonSuccess(resObj));
    },
    failHandler: yield function* (response) {
      const resObj = { stateObj };
      yield put(commonFail(resObj));
      toastr.error(response?.errors[0]);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_DEV,
    failHandlerType: 'CUSTOM',
    apiType: method,
    token: true,
    payload: payloadData,
  });
}
