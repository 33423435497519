/* eslint-disable no-unused-vars */
import { put } from 'redux-saga/effects';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import axiosMain from '../../../http/axios/axios_main';
import {
  getProfileFail,
  getProfileStart,
  getProfileSuccess,
  resendMobileOtpFail,
  resendMobileOtpStart,
  resendMobileOtpSuccess,
  updateNumberFail,
  // updateNumberOtpFail,
  // updateNumberOtpStart,
  // updateNumberOtpSuccess,
  updateNumberStart,
  updateNumberSuccess,
} from '../../actions';

/* eslint-disable import/prefer-default-export */
export function* getProfileSaga() {
  //   const { data } = action.payload;

  yield put(getProfileStart());
  try {
    const response = yield axiosMain.get('/me');
    if (response.status === 200) {
      yield put(getProfileSuccess(response.data));
    } else {
      yield put(getProfileFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      if (error.response.status === 400) {
        yield put(getProfileFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== '' &&
        typeof error.response.data.msg === 'string'
      ) {
        yield put(getProfileFail(error.response.data.msg));
      } else {
        yield put(getProfileFail('Something went wrong! Please try again.'));
      }
    } else {
      yield put(getProfileFail('Something went wrong! Please try again.'));
    }
  }
}

export function* updateNumberSaga(action) {
  const { data } = action.payload;

  yield put(updateNumberStart());
  try {
    const response = yield axiosMain.patch(`/me/mobile`, data);
    console.log('success', response.data.data.message);
    if (response.status === 200) {
      yield put(updateNumberSuccess(response.data));
      yield toastr.success(response.data.data.message);
      yield localStorage.setItem('referenceCode', response.data.data.referenceCode);
    } else {
      yield put(updateNumberFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(updateNumberFail(error.response.data.error.message));
        yield toastr.error(error.response.data.error.message);
      } else if (
        error.response.data.error.message !== undefined &&
        error.response.data.error.message !== '' &&
        typeof error.response.data.error.message === 'string'
      ) {
        yield put(updateNumberFail(error.response.data.error.message));
        yield toastr.error(error.response.data.error.message);
      } else {
        yield put(updateNumberFail('Something went wrong! Please try again.'));
      }
    } else {
      yield put(updateNumberFail('Something went wrong! Please try again.'));
    }
  }
}

export function* resendMobileOtpSaga(action) {
  const { data } = action.payload;

  yield put(resendMobileOtpStart());
  try {
    const response = yield axiosMain.post(`/auth/resend.verification-mobile`, data);
    console.log('success', response.data.data.message);
    if (response.status === 200) {
      yield put(resendMobileOtpSuccess(response.data));
      yield toastr.success(response.data.data.message);
      yield localStorage.setItem('referenceCode', response.data.data.referenceCode);
    } else {
      yield put(resendMobileOtpFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(resendMobileOtpFail(error.response.data.error.message));
        yield toastr.error(error.response.data.error.message);
      } else if (
        error.response.data.error.message !== undefined &&
        error.response.data.error.message !== '' &&
        typeof error.response.data.error.message === 'string'
      ) {
        yield put(resendMobileOtpFail(error.response.data.error.message));
        yield toastr.error(error.response.data.error.message);
      } else {
        yield put(resendMobileOtpFail('Something went wrong! Please try again.'));
      }
    } else {
      yield put(resendMobileOtpFail('Something went wrong! Please try again.'));
    }
  }
}
