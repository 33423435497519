import * as actionLabels from '../../actionLabels';

export const initialState = {
  isLoading: false,
  errorMsg: '',
  userData: null,
  viewUserData: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.SET_DEFAULT:
      return { ...state, isLogin: false };

    case actionLabels.GET_ALL_USERS_START:
      return { ...state, isLoading: true };

    case actionLabels.GET_ALL_USERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userData: payload.data.items,
        errorMsg: '',
      };
    }
    case actionLabels.GET_ALL_USERS_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    case actionLabels.VIEW_USER_START:
      return { ...state, isLoading: true };

    case actionLabels.VIEW_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        viewUserData: payload.data.items,
        errorMsg: '',
      };
    }
    case actionLabels.VIEW_USER_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    default:
      return state;
  }
};
