import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import * as actionLabels from '../actionLabels';
import {
  activityLogsSaga,
  addAdminSaga,
  commonAPISaga,
  deleteAdminSaga,
  editAdminSaga,
  getAllAdminSaga,
  getWhitelistedSaga,
  transactionDetailsSaga,
  viewAdminSaga,
} from './admin/admin';
import {
  loginSaga,
  authenticationValidatorSaga,
  resendOtpSaga,
  activatePasswordSaga,
  resetPasswordSaga,
} from './auth/auth';
import { dashboardStatsSaga, getDashboardDetailsSaga } from './dashboard/dashboard';
import { editIcoSaga, getIcoSaga, updateBatchTransferSaga } from './ico/ico';
import uploadPhaseTimingsSaga, {
  getIcoPhaseDetailsSaga,
  setRoundSaga,
  updateStartDateSaga,
} from './PhaseManagement/PhaseManagement';

import { editPlatformVariablesSaga, getPlatformVariablesSaga } from './platform/platform';
import { getProfileSaga, resendMobileOtpSaga, updateNumberSaga } from './profile/profile';
import { getAllUsersSaga, viewUserSaga } from './user/user';
import {
  getReferralListSaga,
  getReferralPercentSaga,
  updateReferralPercentSaga,
} from './referral/referral';
import { getRewardListSaga } from './reward/reward';

// eslint-disable-next-line import/prefer-default-export
export function* watchAuthentication() {
  yield all([
    takeLatest(actionLabels.LOGIN_SAGA, loginSaga),
    takeLatest(actionLabels.RESEND_OTP_SAGA, resendOtpSaga),
    takeLatest(actionLabels.ACTIVATE_PASSWORD_SAGA, activatePasswordSaga),
    takeLatest(actionLabels.RESET_PASSWORD_SAGA, resetPasswordSaga),
    takeLatest(actionLabels.AUTHENTICATION_VALIDATOR, authenticationValidatorSaga),
  ]);
}

export function* watchUser() {
  yield all([
    takeLatest(actionLabels.GET_ALL_USERS_SAGA, getAllUsersSaga),
    takeLatest(actionLabels.VIEW_USER_SAGA, viewUserSaga),
  ]);
}

export function* watchProfile() {
  yield all([
    takeLatest(actionLabels.GET_PROFILE_SAGA, getProfileSaga),
    takeLatest(actionLabels.UPDATE_NUMBER_SAGA, updateNumberSaga),
    takeLatest(actionLabels.RESEND_MOBILE_OTP_SAGA, resendMobileOtpSaga),
  ]);
}

export function* watchDashboard() {
  yield all([
    takeLatest(actionLabels.DASHBOARD_STATS_SAGA, dashboardStatsSaga),
    takeLatest(actionLabels.GET_DASHBOARD_DETAILS_SAGA, getDashboardDetailsSaga),
  ]);
}

export function* watchPlatform() {
  yield all([
    takeLatest(actionLabels.GET_PLATFORM_VARIABLES_SAGA, getPlatformVariablesSaga),
    takeLatest(actionLabels.EDIT_PLATFORM_VARIABLES_SAGA, editPlatformVariablesSaga),
  ]);
}

export function* watchIco() {
  yield all([
    // takeLatest(actionLabels.GET_BATCH_TRANSFER_SAGA, getBatchTransferSaga),
    takeLatest(actionLabels.UPDATE_BATCH_TRANSFER_SAGA, updateBatchTransferSaga),
    takeLatest(actionLabels.GET_ICO_SAGA, getIcoSaga),
    takeLatest(actionLabels.EDIT_ICO_SAGA, editIcoSaga),
  ]);
}

export function* watchAdmin() {
  yield all([
    takeLatest(actionLabels.GET_ALL_ADMIN_SAGA, getAllAdminSaga),
    takeLatest(actionLabels.ADD_ADMIN_SAGA, addAdminSaga),
    takeLatest(actionLabels.DELETE_ADMIN_SAGA, deleteAdminSaga),
    takeLatest(actionLabels.EDIT_ADMIN_SAGA, editAdminSaga),
    takeLatest(actionLabels.ACTIVITY_LOGS_SAGA, activityLogsSaga),
    takeLatest(actionLabels.VIEW_ADMIN_SAGA, viewAdminSaga),
    takeLatest(actionLabels.TRANSACTION_DETAILS_SAGA, transactionDetailsSaga),
    takeLatest(actionLabels.GET_WHITELISTED_USERS_SAGA, getWhitelistedSaga),
    takeEvery(actionLabels.COMMON_SAGA, commonAPISaga),
  ]);
}

export function* watchPhaseManagement() {
  yield all([
    takeLatest(actionLabels.UPLOAD_PHASE_TIMINGS_SAGA, uploadPhaseTimingsSaga),
    takeLatest(actionLabels.GET_ICO_PHASE_DETAILS_SAGA, getIcoPhaseDetailsSaga),
    takeLatest(actionLabels.UPDATE_START_DATE_SAGA, updateStartDateSaga),
    takeLatest(actionLabels.SET_ROUND_SAGA, setRoundSaga),
  ]);
}

export function* watchReferral() {
  yield all([
    takeLatest(actionLabels.GET_REFERRAL_LIST_SAGA, getReferralListSaga),
    takeLatest(actionLabels.GET_REFERRAL_PERCENT_SAGA, getReferralPercentSaga),
    takeLatest(actionLabels.UPDATE_REFERRAL_PERCENT_SAGA, updateReferralPercentSaga),
  ]);
}

export function* watchReward() {
  yield all([takeLatest(actionLabels.GET_REWARD_LIST_SAGA, getRewardListSaga)]);
}
