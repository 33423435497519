/* eslint-disable no-unused-vars */
import { put } from 'redux-saga/effects';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import axiosMain from '../../../http/axios/axios_main';
import {
  getIcoPhaseDetailsFail,
  getIcoPhaseDetailsStart,
  getIcoPhaseDetailsSuccess,
  setRoundFail,
  setRoundStart,
  setRoundSuccess,
  updateStartDateFail,
  updateStartDateStart,
  updateStartDateSuccess,
  uploadPhaseTimingsFail,
  uploadPhaseTimingsStart,
  uploadPhaseTimingsSuccess,
} from '../../actions';
import errorHandler from '../../../utils/apiHandler';

import { SET_ROUND_URL } from '../../../apis';

export default function* uploadPhaseTimingsSaga(action) {
  const { data } = action.payload;

  yield put(uploadPhaseTimingsStart());
  try {
    const response = yield axiosMain.post(`/admin/set-phase`, data);
    if (response.status === 200) {
      yield put(uploadPhaseTimingsSuccess(response.data));
      yield toastr.success(response.data.data.message);
    } else {
      yield put(uploadPhaseTimingsFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(uploadPhaseTimingsFail(error.response.data.error.message));
        yield toastr.error(error.response.data.error.message);
      } else if (
        error.response.data.error.message !== undefined &&
        error.response.data.error.message !== '' &&
        typeof error.response.data.error.message === 'string'
      ) {
        yield put(uploadPhaseTimingsFail(error.response.data.error.message));
        yield toastr.error(error.response.data.error.message);
      } else {
        yield put(uploadPhaseTimingsFail('Something went wrong! Please try again.'));
      }
    } else {
      yield put(uploadPhaseTimingsFail('Something went wrong! Please try again.'));
    }
  }
}

// GET_ICO_PHASE_DETAILS
export function* getIcoPhaseDetailsSaga() {
  // const { data } = action.payload;

  yield put(getIcoPhaseDetailsStart());
  try {
    const response = yield axiosMain.get(`/ico/rounds`);
    if (response?.status === 200) {
      yield put(getIcoPhaseDetailsSuccess(response?.data));
    } else {
      yield put(getIcoPhaseDetailsFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error?.response !== undefined &&
      error?.response?.status !== undefined
    ) {
      if (error?.response?.status === 400) {
        yield put(getIcoPhaseDetailsFail(error?.response?.data?.msg));
      } else if (
        error?.response?.data?.msg !== undefined &&
        error?.response?.data?.msg !== '' &&
        typeof error?.response?.data?.msg === 'string'
      ) {
        yield put(getIcoPhaseDetailsFail(error?.response?.data?.msg));
      } else {
        yield put(getIcoPhaseDetailsFail('Something went wrong! Please try again.'));
      }
    } else {
      yield put(getIcoPhaseDetailsFail('Something went wrong! Please try again.'));
    }
  }
}

// UPDATE_START_DATE
export function* updateStartDateSaga(action) {
  const { phaseData, handleGetIcoPhaseDetails } = action?.payload;
  yield put(updateStartDateStart());
  yield errorHandler({
    endpoint: SET_ROUND_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(updateStartDateSuccess(data));
      if (handleGetIcoPhaseDetails) handleGetIcoPhaseDetails();
    },
    failHandler: yield function* (response) {
      yield put(updateStartDateFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_DEV,
    failHandlerType: 'CUSTOM',
    payload: phaseData,
    apiType: 'patch',
    token: true,
  });
}

// SET_ROUND
export function* setRoundSaga(action) {
  const { phase, handleGetIcoPhaseDetails } = action?.payload;
  yield put(setRoundStart());
  yield errorHandler({
    endpoint: SET_ROUND_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(setRoundSuccess(data));
      if (handleGetIcoPhaseDetails) handleGetIcoPhaseDetails();
    },
    failHandler: yield function* (response) {
      yield put(setRoundFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_DEV,
    failHandlerType: 'CUSTOM',
    payload: phase,
    apiType: 'post',
    token: true,
  });
}
