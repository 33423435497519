/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { put } from 'redux-saga/effects';
import errorHandler from '../../../utils/apiHandler';

import { getRewardListFail, getRewardListStart, getRewardListSuccess } from '../../actions';
import { GET_REWARD_LIST_URL } from '../../../apis';

export function* getRewardListSaga() {
  yield put(getRewardListStart());
  yield errorHandler({
    endpoint: GET_REWARD_LIST_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getRewardListSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getRewardListFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_DEV,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}
