import * as actionLabels from '../../actionLabels';

// FOR LOGIN
export const uploadPhaseTimingsStart = payload => ({
  type: actionLabels.UPLOAD_PHASE_TIMINGS_START,
  payload,
});

export const uploadPhaseTimings = payload => ({
  type: actionLabels.UPLOAD_PHASE_TIMINGS_SAGA,
  payload,
});

export const uploadPhaseTimingsSuccess = payload => ({
  type: actionLabels.UPLOAD_PHASE_TIMINGS_SUCCESS,
  payload,
});

export const uploadPhaseTimingsFail = payload => ({
  type: actionLabels.UPLOAD_PHASE_TIMINGS_FAIL,
  payload,
});

// GET_ICO_PHASE_DETAILS_SAGA
export const getIcoPhaseDetailsStart = payload => ({
  type: actionLabels.GET_ICO_PHASE_DETAILS_START,
  payload,
});

export const getIcoPhaseDetails = payload => ({
  type: actionLabels.GET_ICO_PHASE_DETAILS_SAGA,
  payload,
});

export const getIcoPhaseDetailsSuccess = payload => ({
  type: actionLabels.GET_ICO_PHASE_DETAILS_SUCCESS,
  payload,
});

export const getIcoPhaseDetailsFail = payload => ({
  type: actionLabels.GET_ICO_PHASE_DETAILS_FAIL,
  payload,
});

// UPDATE_START_DATE_SAGA
export const updateStartDateStart = payload => ({
  type: actionLabels.UPDATE_START_DATE_START,
  payload,
});

export const updateStartDateAction = payload => ({
  type: actionLabels.UPDATE_START_DATE_SAGA,
  payload,
});

export const updateStartDateSuccess = payload => ({
  type: actionLabels.UPDATE_START_DATE_SUCCESS,
  payload,
});

export const updateStartDateFail = payload => ({
  type: actionLabels.UPDATE_START_DATE_FAIL,
  payload,
});

// SET_ROUND_SAGA
export const setRoundStart = payload => ({
  type: actionLabels.SET_ROUND_START,
  payload,
});

export const setRoundAction = payload => ({
  type: actionLabels.SET_ROUND_SAGA,
  payload,
});

export const setRoundSuccess = payload => ({
  type: actionLabels.SET_ROUND_SUCCESS,
  payload,
});

export const setRoundFail = payload => ({
  type: actionLabels.SET_ROUND_FAIL,
  payload,
});
