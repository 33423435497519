import * as actionLabels from '../../actionLabels';

// get referral list
export const getReferralListAction = payload => ({
  type: actionLabels.GET_REFERRAL_LIST_SAGA,
  payload,
});
export const getReferralListStart = payload => ({
  type: actionLabels.GET_REFERRAL_LIST_START,
  payload,
});

export const getReferralListSuccess = payload => ({
  type: actionLabels.GET_REFERRAL_LIST_SUCCESS,
  payload,
});

export const getReferralListFail = payload => ({
  type: actionLabels.GET_REFERRAL_LIST_FAIL,
  payload,
});

// get referral %
export const getReferralPercentAction = payload => ({
  type: actionLabels.GET_REFERRAL_PERCENT_SAGA,
  payload,
});
export const getReferralPercentStart = payload => ({
  type: actionLabels.GET_REFERRAL_PERCENT_START,
  payload,
});

export const getReferralPercentSuccess = payload => ({
  type: actionLabels.GET_REFERRAL_PERCENT_SUCCESS,
  payload,
});

export const getReferralPercentFail = payload => ({
  type: actionLabels.GET_REFERRAL_PERCENT_FAIL,
  payload,
});

// update referral %
export const updateReferralPercentAction = payload => ({
  type: actionLabels.UPDATE_REFERRAL_PERCENT_SAGA,
  payload,
});
export const updateReferralPercentStart = payload => ({
  type: actionLabels.UPDATE_REFERRAL_PERCENT_START,
  payload,
});

export const updateReferralPercentSuccess = payload => ({
  type: actionLabels.UPDATE_REFERRAL_PERCENT_SUCCESS,
  payload,
});

export const updateReferralPercentFail = payload => ({
  type: actionLabels.UPDATE_REFERRAL_PERCENT_FAIL,
  payload,
});
