import { put } from 'redux-saga/effects';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import {
  loginSuccess,
  loginFail,
  loginStart,
  resendOtpStart,
  resendOtpSuccess,
  resendOtpFail,
  activatePasswordStart,
  activatePasswordSuccess,
  activatePasswordFail,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFail,
} from '../../actions';
import axiosMain from '../../../http/axios/axios_main';

export function* loginSaga(action) {
  const { data } = action.payload;
  yield put(loginStart(data));
  try {
    const response = yield axiosMain.post('/auth/sign-in', data);
    if (response.status === 200) {
      yield put(loginSuccess(response.data));
      // yield call([localStorage, 'setItem'], 'authToken', response.data.token);
      console.log(response.data.data.items, 'items');
      if (response?.data?.data?.token) {
        yield localStorage.setItem('authToken', response?.data?.data?.token);
      }
      if (response?.data?.data?.referenceCode) {
        yield localStorage.setItem('referenceCode', response.data.data.referenceCode);
      }
      if (response?.data?.data?.items) {
        yield localStorage.setItem('countryCode', response.data.data.items[0].countryCode);
        yield localStorage.setItem('phoneNumber', response.data.data.items[0].phoneNumber);
      }
    } else {
      yield put(loginFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    console.log(error?.response?.data?.error?.message, 'loginerr');
    if (
      error !== undefined &&
      error?.response !== undefined &&
      error?.response?.status !== undefined
    ) {
      if (error?.response?.status === 400) {
        yield put(loginFail(error?.response?.data?.error?.message));
      } else if (
        error?.response?.data?.error?.message !== undefined &&
        error?.response?.data?.error?.message !== '' &&
        typeof error?.response?.data?.error?.message === 'string'
      ) {
        yield put(loginFail(error?.response?.data?.error?.message));
      } else {
        yield put(loginFail('Something went wrong! Please try again.'));
      }
    } else {
      yield put(loginFail('Something went wrong! Please try again.'));
    }
  }
}

export function* resendOtpSaga(action) {
  const { data } = action.payload;
  console.log(data, 'data-resend');
  yield put(resendOtpStart(data));
  try {
    const response = yield axiosMain.post('auth/resend.2FA', data);
    if (response.status === 200) {
      yield put(resendOtpSuccess(response.data));
      yield localStorage.setItem('referenceCode', response.data.data.referenceCode);
    } else {
      yield put(resendOtpFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(resendOtpFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== '' &&
        typeof error.response.data.msg === 'string'
      ) {
        yield put(resendOtpFail(error.response.data.msg));
      } else {
        yield put(resendOtpFail('Server error! Please try again.'));
      }
    } else {
      yield put(resendOtpFail('Something went wrong! Please try again.'));
    }
  }
}

export function* activatePasswordSaga(action) {
  const { data } = action.payload;
  yield put(activatePasswordStart(data));
  try {
    const response = yield axiosMain.post('auth/resend.activation-password', data);
    if (response.status === 200) {
      yield put(activatePasswordSuccess(response.data));
      toastr.success('Activation password sent successfully');
    } else {
      yield put(activatePasswordFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(activatePasswordFail('Something went wrong! Please try again.'));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== '' &&
        typeof error.response.data.msg === 'string'
      ) {
        yield put(activatePasswordFail(error.response.data.message));
      } else {
        yield put(activatePasswordFail('Server error! Please try again.'));
      }
    } else {
      yield put(activatePasswordFail('Something went wrong! Please try again.'));
    }
  }
}

export function* resetPasswordSaga(action) {
  const { data } = action.payload;
  yield put(resetPasswordStart(data));
  try {
    const response = yield axiosMain.post('auth/password.reset', data);
    if (response.status === 200) {
      yield put(resetPasswordSuccess(response.data));
      yield localStorage.setItem('referenceCode', response.data.data.referenceCode);

      yield toastr.success(response.data.data.message);
    } else {
      yield put(resetPasswordFail('Something went wrong! Please try again.'));
      yield toastr.error('Something went wrong');
    }
  } catch (error) {
    console.log(error, 'reset-err');
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(resetPasswordFail(error.response.data.error.message));
        yield toastr.error(error.response.data.error.message);
      } else if (
        error.response.data.error.message !== undefined &&
        error.response.data.error.message !== '' &&
        typeof error.response.data.error.message === 'string'
      ) {
        yield put(resetPasswordFail(error.response.data.error.message));
        yield toastr.error(error.response.data.error.message);
      } else {
        yield put(resetPasswordFail('Server error! Please try again.'));
        yield toastr.error('Server error! Please try again.');
      }
    } else {
      yield put(resetPasswordFail('Something went wrong! Please try again.'));
      yield toastr.error('Server error! Please try again.');
    }
  }
}

export function* authenticationValidatorSaga() {
  // yield put(loginStart());
  const token = yield localStorage.getItem('authToken');
  if (!token) {
    // yield put(logout()); // logout action
  } else {
    yield put(loginSuccess({ token }));
  }
}
