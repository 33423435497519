/* eslint-disable import/prefer-default-export */
import { put } from 'redux-saga/effects';

import axiosMain from '../../../http/axios/axios_main';
import {
  getAllUsersFail,
  getAllUsersStart,
  getAllUsersSuccess,
  viewUserFail,
  viewUserStart,
  viewUserSuccess,
} from '../../actions';

export function* getAllUsersSaga() {
  //   const { data } = action.payload;

  yield put(getAllUsersStart());
  try {
    const response = yield axiosMain.get('/user');
    if (response.status === 200) {
      yield put(getAllUsersSuccess(response.data));
    } else {
      yield put(getAllUsersFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllUsersFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== '' &&
        typeof error.response.data.msg === 'string'
      ) {
        yield put(getAllUsersFail(error.response.data.msg));
      } else {
        yield put(getAllUsersFail('Something went wrong! Please try again.'));
      }
    } else {
      yield put(getAllUsersFail('Something went wrong! Please try again.'));
    }
  }
}

export function* viewUserSaga(action) {
  const { _id } = action.payload;

  yield put(viewUserStart());
  try {
    const response = yield axiosMain.get(`/user/${_id}`);
    if (response.status === 200) {
      yield put(viewUserSuccess(response.data));
    } else {
      yield put(viewUserFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(viewUserFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== '' &&
        typeof error.response.data.msg === 'string'
      ) {
        yield put(viewUserFail(error.response.data.msg));
      } else {
        yield put(viewUserFail('Something went wrong! Please try again.'));
      }
    } else {
      yield put(viewUserFail('Something went wrong! Please try again.'));
    }
  }
}
