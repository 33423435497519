export const UPDATE_BATCH_TRANSFER_SAGA = 'UPDATE_BATCH_TRANSFER_SAGA';
export const UPDATE_BATCH_TRANSFER_START = 'UPDATE_BATCH_TRANSFER_START';
export const UPDATE_BATCH_TRANSFER_SUCCESS = 'UPDATE_BATCH_TRANSFER_SUCCESS';
export const UPDATE_BATCH_TRANSFER_FAIL = 'UPDATE_BATCH_TRANSFER_FAIL';

export const GET_ICO_SAGA = 'GET_ICO_SAGA';
export const GET_ICO_START = 'GET_ICO_START';
export const GET_ICO_SUCCESS = 'GET_ICO_SUCCESS';
export const GET_ICO_FAIL = 'GET_ICO_FAIL';

export const EDIT_ICO_SAGA = 'EDIT_ICO_SAGA';
export const EDIT_ICO_START = 'EDIT_ICO_START';
export const EDIT_ICO_SUCCESS = 'EDIT_ICO_SUCCESS';
export const EDIT_ICO_FAIL = 'EDIT_ICO_FAIL';
