import * as actionLabels from '../../actionLabels';

// FOR LOGIN
export const loginStart = payload => ({
  type: actionLabels.LOGIN_START,
  payload,
});

export const login = payload => ({
  type: actionLabels.LOGIN_SAGA,
  payload,
});

export const loginSuccess = payload => ({
  type: actionLabels.LOGIN_SUCCESS,
  payload,
});

export const loginFail = payload => ({
  type: actionLabels.LOGIN_FAIL,
  payload,
});

// FOR ACTIVATE PASSWORD

export const activatePasswordStart = () => ({
  type: actionLabels.ACTIVATE_PASSWORD_START,
});

export const activatePassword = payload => ({
  type: actionLabels.ACTIVATE_PASSWORD_SAGA,
  payload,
});

export const activatePasswordSuccess = payload => ({
  type: actionLabels.ACTIVATE_PASSWORD_SUCCESS,
  payload,
});

export const activatePasswordFail = payload => ({
  type: actionLabels.ACTIVATE_PASSWORD_FAIL,
  payload,
});

export const resetActivatePassword = payload => ({
  type: actionLabels.RESET_ACTIVATE_PASSWORD,
  payload,
});



// FOR RESENDING OTP

export const resendOtpStart = () => ({
  type: actionLabels.RESEND_OTP_START,
});

export const resendOtp = payload => ({
  type: actionLabels.RESEND_OTP_SAGA,
  payload,
});

export const resendOtpSuccess = payload => ({
  type: actionLabels.RESEND_OTP_SUCCESS,
  payload,
});

export const resendOtpFail = payload => ({
  type: actionLabels.RESEND_OTP_FAIL,
  payload,
});

// FOR RESETTING PASSWORD

export const resetPasswordStart = () => ({
  type: actionLabels.RESET_PASSWORD_START,
});

export const resetPassword = payload => ({
  type: actionLabels.RESET_PASSWORD_SAGA,
  payload,
});

export const resetPasswordSuccess = payload => ({
  type: actionLabels.RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPasswordFail = payload => ({
  type: actionLabels.RESET_PASSWORD_FAIL,
  payload,
});

export const authenticationValidator = () => ({
  type: actionLabels.AUTHENTICATION_VALIDATOR,
});

export const setDefault = () => ({
  type: actionLabels.SET_DEFAULT,
});


// WHITELISTED USERS

export const getWhitelistedUsersStart = () => ({
  type: actionLabels.GET_WHITELISTED_USERS_START,
});

export const getWhitelistedUsers = payload => ({
  type: actionLabels.GET_WHITELISTED_USERS_SAGA,
  payload,
});

export const getWhitelistedUsersSuccess = payload => ({
  type: actionLabels.GET_WHITELISTED_USERS_SUCCESS,
  payload,
});

export const getWhitelistedUsersFail = payload => ({
  type: actionLabels.GET_WHITELISTED_USERS_FAIL,
  payload,
});