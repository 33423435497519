import React from 'react';

export const guestRoutes = [
  {
    path: '/signin',
    name: 'Signin',
    exact: true,
    component: React.lazy(() => import('../../views/auth/Login/Login')),
  },
  {
    path: '/otp',
    name: 'OTP',
    exact: true,
    component: React.lazy(() => import('../../views/auth/Login/Otp')),
  },
];

export const userRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    exact: true,
    component: React.lazy(() => import('../../views/User/Dashboard')),
  },
  {
    path: '/admin-management',
    name: 'AdminManagement',
    exact: true,
    component: React.lazy(() => import('../../views/User/AdminManagement')),
  },

  {
    path: '/admin-management/:id',
    name: 'ViewAdminManagement',
    exact: true,
    component: React.lazy(() => import('../../views/User/AdminManagement/ViewAdmin')),
  },
  {
    path: '/admin-management/:id/activity-log',
    name: 'AdminActivityLog',
    exact: true,
    component: React.lazy(() => import('../../views/User/AdminManagement/ActivityLog')),
  },
  {
    path: '/transaction-management',
    name: 'TransactionManagement',
    exact: true,
    component: React.lazy(() => import('../../views/User/TransactionManagement')),
  },
  {
    path: '/user-management',
    name: 'UserManagement',
    exact: true,
    component: React.lazy(() => import('../../views/User/UserManagement')),
  },
  {
    path: '/referral-management',
    name: 'ReferralManagement',
    exact: true,
    component: React.lazy(() => import('../../views/User/ReferralManagement')),
  },
  {
    path: '/reward-management',
    name: 'RewardManagement',
    exact: true,
    component: React.lazy(() => import('../../views/User/RewardManagement')),
  },
  {
    path: '/admin-profile',
    name: 'AdminProfile',
    exact: true,
    component: React.lazy(() => import('../../views/User/AdminProfile')),
  },
  {
    path: '/admin-profile/update-password',
    name: 'UpdatePassword',
    exact: true,
    component: React.lazy(() => import('../../views/User/AdminProfile/UpdatePassword')),
  },
  {
    path: '/private-closed-ico',
    name: 'PrivateICO',
    exact: true,
    component: React.lazy(() => import('../../views/User/PrivateICO')),
  },
  {
    path: '/platform-variables',
    name: 'PlatformVariables',
    exact: true,
    component: React.lazy(() => import('../../views/User/PlatformVariables')),
  },
  {
    path: '/phase-management',
    name: 'PhaseManagement',
    exact: true,
    component: React.lazy(() => import('../../views/User/PhaseManagement')),
  },
  {
    path: '/whitelist',
    name: 'Whitelist',
    exact: true,
    component: React.lazy(() => import('../../views/User/Whitelist/Whitelist')),
  },
  {
    redirectRoute: true,
    name: 'dashboardRedirect',
    path: '/dashboard',
  },
];
