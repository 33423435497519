import * as actionLabels from '../../actionLabels';

// FOR GET DASHBOARD STATS
export const dashboardStatsStart = payload => ({
  type: actionLabels.DASHBOARD_STATS_START,
  payload,
});

export const dashboardStats = payload => ({
  type: actionLabels.DASHBOARD_STATS_SAGA,
  payload,
});

export const dashboardStatsSuccess = payload => ({
  type: actionLabels.DASHBOARD_STATS_SUCCESS,
  payload,
});

export const dashboardStatsFail = payload => ({
  type: actionLabels.DASHBOARD_STATS_FAIL,
  payload,
});

// FOR GET DASHBOARD Details
export const getDashboardDetailsStart = payload => ({
  type: actionLabels.GET_DASHBOARD_DETAILS_START,
  payload,
});

export const getDashboardDetails = payload => ({
  type: actionLabels.GET_DASHBOARD_DETAILS_SAGA,
  payload,
});

export const getDashboardDetailsSuccess = payload => ({
  type: actionLabels.GET_DASHBOARD_DETAILS_SUCCESS,
  payload,
});

export const getDashboardDetailsFail = payload => ({
  type: actionLabels.GET_DASHBOARD_DETAILS_FAIL,
  payload,
});
