// get referral list
export const GET_REFERRAL_LIST_SAGA = 'GET_REFERRAL_LIST_SAGA';
export const GET_REFERRAL_LIST_START = 'GET_REFERRAL_LIST_START';
export const GET_REFERRAL_LIST_SUCCESS = 'GET_REFERRAL_LIST_SUCCESS';
export const GET_REFERRAL_LIST_FAIL = 'GET_REFERRAL_LIST_FAIL';

// get referral %
export const GET_REFERRAL_PERCENT_SAGA = 'GET_REFERRAL_PERCENT_SAGA';
export const GET_REFERRAL_PERCENT_START = 'GET_REFERRAL_PERCENT_START';
export const GET_REFERRAL_PERCENT_SUCCESS = 'GET_REFERRAL_PERCENT_SUCCESS';
export const GET_REFERRAL_PERCENT_FAIL = 'GET_REFERRAL_PERCENT_FAIL';

// update referral %
export const UPDATE_REFERRAL_PERCENT_SAGA = 'UPDATE_REFERRAL_PERCENT_SAGA';
export const UPDATE_REFERRAL_PERCENT_START = 'UPDATE_REFERRAL_PERCENT_START';
export const UPDATE_REFERRAL_PERCENT_SUCCESS = 'UPDATE_REFERRAL_PERCENT_SUCCESS';
export const UPDATE_REFERRAL_PERCENT_FAIL = 'UPDATE_REFERRAL_PERCENT_FAIL';
