/* eslint-disable import/prefer-default-export */

import { put } from 'redux-saga/effects';
import axiosMain from '../../../http/axios/axios_main';
import {
  editPlatformVariablesFail,
  editPlatformVariablesStart,
  editPlatformVariablesSuccess,
  getPlatformVariables,
  getPlatformVariablesFail,
  getPlatformVariablesStart,
  getPlatformVariablesSuccess,
} from '../../actions';

export function* getPlatformVariablesSaga() {
  yield put(getPlatformVariablesStart());
  try {
    const response = yield axiosMain.get('/ico');
    if (response.status === 200) {
      yield put(getPlatformVariablesSuccess(response.data));
    } else {
      yield put(getPlatformVariablesFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getPlatformVariablesFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== '' &&
        typeof error.response.data.msg === 'string'
      ) {
        yield put(getPlatformVariablesFail(error.response.data.msg));
      } else {
        yield put(getPlatformVariablesFail('Something went wrong! Please try again.'));
      }
    } else {
      yield put(getPlatformVariablesFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editPlatformVariablesSaga(action) {
  const { data } = action.payload;
  yield put(editPlatformVariablesStart());
  try {
    const response = yield axiosMain.patch('/ico', data);
    if (response.status === 200) {
      yield put(editPlatformVariablesSuccess(response.data));
      yield put(getPlatformVariables());
    } else {
      yield put(editPlatformVariablesFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editPlatformVariablesFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== '' &&
        typeof error.response.data.msg === 'string'
      ) {
        yield put(editPlatformVariablesFail(error.response.data.msg));
      } else {
        yield put(editPlatformVariablesFail('Something went wrong! Please try again.'));
      }
    } else {
      yield put(editPlatformVariablesFail('Something went wrong! Please try again.'));
    }
  }
}
