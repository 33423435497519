/* eslint-disable no-else-return */

import { initializeConnector } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { MetaMask } from '@web3-react/metamask';
import { getIsInjected, getIsMetaMaskWallet } from '.';
import { isMobile } from './userAgent';
import { FOX } from '../assets/images';

function onError(error) {
  console.debug(`web3-react error: ${error}`);
}

const getShouldAdvertiseMetaMask = () => !getIsMetaMaskWallet() && !isMobile && !getIsInjected();

export const [web3Injected, web3InjectedHooks] = initializeConnector(
  actions => new MetaMask({ actions, onError }),
);

export const injectedConnection = {
  getName: () => 'METAMASK',
  connector: web3Injected,
  hooks: web3InjectedHooks,
  type: 'INJECTED',
  getIcon: () => FOX,
  shouldDisplay: () => getIsMetaMaskWallet() || getShouldAdvertiseMetaMask(),

  // If on non-injected, non-mobile browser, prompt user to install Metamask
  overrideActivate: () => {
    if (getShouldAdvertiseMetaMask()) {
      window.open('https://metamask.io/', 'inst_metamask');
      return true;
    }
    return false;
  },
};

export const connections = [injectedConnection];

export const injected = new InjectedConnector({
  supportedChainIds: [80002],
});
