import * as actionLabels from '../../actionLabels';

export const initialState = {
  getAllAdminLoading: false,
  isLoading: false,
  isTxnLoading: false,
  adminData: null,
  errorMsg: '',
  activityLogsData: null,
  viewAdminData: null,
  transactionData: null,
  txnResponseData: null,
  isShow: false,
  error: false,
  whitelistLoader: false,
whitelistedUsers:[]
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.SET_DEFAULT:
      return { ...state, isLogin: false };

    case actionLabels.GET_ALL_ADMIN_START:
      return { ...state, getAllAdminLoading: true };

    case actionLabels.GET_ALL_ADMIN_SUCCESS: {
      return {
        ...state,
        isShow: true,
        getAllAdminLoading: false,
        adminData: payload.data.items,
        errorMsg: '',
      };
    }
    case actionLabels.GET_ALL_ADMIN_FAIL: {
      return { ...state, getAllAdminLoading: false, errorMsg: payload };
    }

    case actionLabels.ADD_ADMIN_START:
      return { ...state, isLoading: true, errorMsg: '', error: true };

    case actionLabels.ADD_ADMIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        adminData: payload.data.items,
        errorMsg: '',
        error: false,
      };
    }
    case actionLabels.ADD_ADMIN_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload, error: true };
    }

    case actionLabels.DELETE_ADMIN_START:
      return { ...state, isLoading: true };

    case actionLabels.DELETE_ADMIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        adminData: payload.data.items,
        errorMsg: '',
      };
    }
    case actionLabels.DELETE_ADMIN_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    case actionLabels.EDIT_ADMIN_START:
      return { ...state, isLoading: true, error: true };

    case actionLabels.EDIT_ADMIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        adminData: payload.data.items,
        errorMsg: '',
        error: false,
      };
    }
    case actionLabels.EDIT_ADMIN_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload, error: true };
    }

    case actionLabels.ACTIVITY_LOGS_START:
      return { ...state, isLoading: true };

    case actionLabels.ACTIVITY_LOGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        activityLogsData: payload.data.items,
        errorMsg: '',
      };
    }
    case actionLabels.ACTIVITY_LOGS_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    case actionLabels.VIEW_ADMIN_START:
      return { ...state, isLoading: true };

    case actionLabels.VIEW_ADMIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        viewAdminData: payload.data.items,
        errorMsg: '',
      };
    }
    case actionLabels.VIEW_ADMIN_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    case actionLabels.TRANSACTION_DETAILS_START:
      return { ...state, isTxnLoading: true };

    case actionLabels.TRANSACTION_DETAILS_SUCCESS: {
      return {
        ...state,
        isTxnLoading: false,
        transactionData: payload?.data?.items?.[0],
        txnResponseData: payload,
        errorMsg: '',
      };
    }
    case actionLabels.TRANSACTION_DETAILS_FAIL: {
      return { ...state, isTxnLoading: false, errorMsg: payload };
    }
 
    case actionLabels.GET_WHITELISTED_USERS_START:
      return { ...state, whitelistLoader: true };

    case actionLabels.GET_WHITELISTED_USERS_SUCCESS: {
      return {
        ...state,
        whitelistLoader: false,
        whitelistedUsers : payload?.items
      };
    }
    case actionLabels.GET_WHITELISTED_USERS_FAIL: {
      return { ...state, whitelistLoader: false, whitelistedUsers: payload };
    }

    case actionLabels.COMMON_START:
      return {
        ...state,
        [payload.stateObj]: {
            isLoading: true
        }
      };   

    case actionLabels.COMMON_SUCCESS: {
      return {
        ...state,
        [payload.stateObj]: {
            isLoading: false,      
            errorMsg: '',
            ...payload
        }
      };
    }

    case actionLabels.COMMON_FAIL:
      return {
        ...state,
        [payload.stateObj]: {
            isLoading: false,
            ...payload
        }
    }; 

    default:
      return state;
  }
};
