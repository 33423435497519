/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactDOM from 'react-dom';
// import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Provider } from 'react-redux';
import rootReducer from './store/reducer';

import {
  watchAdmin,
  watchAuthentication,
  watchDashboard,
  watchIco,
  watchPhaseManagement,
  watchPlatform,
  watchProfile,
  watchUser,
  watchReferral,
  watchReward,
} from './store/sagas';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { connections } from './utils/connectors';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(watchAuthentication);
sagaMiddleware.run(watchAdmin);
sagaMiddleware.run(watchUser);
sagaMiddleware.run(watchProfile);
sagaMiddleware.run(watchDashboard);
sagaMiddleware.run(watchPlatform);
sagaMiddleware.run(watchIco);
sagaMiddleware.run(watchPhaseManagement);
sagaMiddleware.run(watchReferral);
sagaMiddleware.run(watchReward);

const connectors = connections.map(({ hooks, connector }) => [connector, hooks]);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Web3ReactProvider connectors={connectors}>
        <App />
      </Web3ReactProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
