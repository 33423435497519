export const GET_ALL_USERS_SAGA = 'GET_ALL_USERS_SAGA';
export const GET_ALL_USERS_START = 'GET_ALL_USERS_START';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL';

export const VIEW_USER_SAGA = 'VIEW_USER_SAGA';
export const VIEW_USER_START = 'VIEW_USER_START';
export const VIEW_USER_SUCCESS = 'VIEW_USER_SUCCESS';
export const VIEW_USER_FAIL = 'VIEW_USER_FAIL';

export const RESEND_MOBILE_OTP_SAGA = 'RESEND_MOBILE_OTP_SAGA';
export const RESEND_MOBILE_OTP_START = 'RESEND_MOBILE_OTP_START';
export const RESEND_MOBILE_OTP_SUCCESS = 'RESEND_MOBILE_OTP_SUCCESS';
export const RESEND_MOBILE_OTP_FAIL = 'RESEND_MOBILE_OTP_FAIL';
