import { put } from 'redux-saga/effects';
import toastr from 'toastr';
import axiosMain from '../../../http/axios/axios_main';
import 'toastr/build/toastr.min.css';
import {
  editIcoFail,
  editIcoStart,
  editIcoSuccess,
  getIco,
  getIcoFail,
  getIcoStart,
  getIcoSuccess,
  updateBatchTransferFail,
  updateBatchTransferStart,
  updateBatchTransferSuccess,
} from '../../actions';

export function* updateBatchTransferSaga() {
  yield put(updateBatchTransferStart());
  try {
    const response = yield axiosMain.patch('/ico/batch-transfer-input');
    if (response.status === 200) {
      yield put(updateBatchTransferSuccess(response.data));
    } else {
      yield put(updateBatchTransferFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(updateBatchTransferFail(error.response.data.msg));
        yield toastr.error(error.response.data.error.message);
      } else if (
        error.response.data.error.message !== undefined &&
        error.response.data.error.message !== '' &&
        typeof error.response.data.error.message === 'string'
      ) {
        yield put(updateBatchTransferFail(error.response.data.msg));
        yield toastr.error(error.response.data.error.message);
      } else {
        yield put(updateBatchTransferFail('Something went wrong! Please try again.'));
        yield toastr.error('Something went wrong! Please try again.');
      }
    } else {
      yield put(updateBatchTransferFail('Something went wrong! Please try again.'));
      yield toastr.error('Something went wrong! Please try again.');
    }
  }
}

export function* getIcoSaga(action) {
  const { data } = action.payload;
  yield put(getIcoStart());
  try {
    const response = yield axiosMain.get(`ico/ico-sales/${data}`);
    if (response.status === 200) {
      yield put(getIcoSuccess(response.data));
    } else {
      yield put(getIcoFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getIcoFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== '' &&
        typeof error.response.data.msg === 'string'
      ) {
        yield put(getIcoFail(error.response.data.msg));
      } else {
        yield put(getIcoFail('Something went wrong! Please try again.'));
      }
    } else {
      yield put(getIcoFail('Something went wrong! Please try again.'));
    }
  }
}

export function* editIcoSaga(action) {
  const { data } = action.payload;
  const { _id } = action.payload;
  const { phase } = action.payload;
  yield put(editIcoStart());
  try {
    const response = yield axiosMain.patch(`/ico/ico-sales/${_id}`, data);
    if (response.status === 200) {
      yield put(editIcoSuccess(response.data));
      yield put(getIco({ data: phase }));
    } else {
      yield put(editIcoFail('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editIcoFail(error.response.data.msg));
        yield toastr.error(error.response.data.error.message);
      } else if (
        error.response.data.error.message !== undefined &&
        error.response.data.error.message !== '' &&
        typeof error.response.data.error.message === 'string'
      ) {
        yield put(editIcoFail(error.response.data.msg));
        yield toastr.error(error.response.data.error.message);
      } else {
        yield put(editIcoFail('Something went wrong! Please try again.'));
        yield toastr.error('Something went wrong! Please try again.');
      }
    } else {
      yield put(editIcoFail('Something went wrong! Please try again.'));
      yield toastr.error('Something went wrong! Please try again.');
    }
  }
}
