import * as actionLabels from '../../actionLabels';

// GET ALL USERS

export const getAllUsersStart = payload => ({
  type: actionLabels.GET_ALL_USERS_START,
  payload,
});

export const getAllUsers = payload => ({
  type: actionLabels.GET_ALL_USERS_SAGA,
  payload,
});

export const getAllUsersSuccess = payload => ({
  type: actionLabels.GET_ALL_USERS_SUCCESS,
  payload,
});

export const getAllUsersFail = payload => ({
  type: actionLabels.GET_ALL_USERS_FAIL,
  payload,
});

// VIEW USER

export const viewUserStart = payload => ({
  type: actionLabels.VIEW_USER_START,
  payload,
});

export const viewUser = payload => ({
  type: actionLabels.VIEW_USER_SAGA,
  payload,
});

export const viewUserSuccess = payload => ({
  type: actionLabels.VIEW_USER_SUCCESS,
  payload,
});

export const viewUserFail = payload => ({
  type: actionLabels.VIEW_USER_FAIL,
  payload,
});
